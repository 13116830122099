<template>
    <div>
        내아이템 프리미엄 마켓 등록
        <item-list-component :item-type="myItemList"></item-list-component>
    </div>
</template>

<script>
import ItemListComponent from "../../../components/item/ItemListComponent";
export default {
    name: "ItemWriteMyItemLayout",
    mixins: [],
    components: {ItemListComponent},
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {},
    watch: {},
}
</script>

<style scoped>

</style>